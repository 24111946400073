<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" @failure="formFailure"
            :save-params="{api_key: currentUser.api_key}">

        <div class="row">
            <validated-input class="col-6" label="Partner Name" v-model="model.name"
                             :rules="rules.name" :disabled="loading"/>
            <file-input class="c-file-input col-6" label="Logo" v-model="model.logo" :disabled="loading"/>
        </div>
        <div class="row">
            <div class="col">
                <image-info :width="270" :height="160"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <validated-textarea label="Description" v-model="model.description"
                                    :rules="rules.description" :disabled="loading"/>
            </div>
        </div>

        <div class="row">
            <validated-input class="col-6" label="Website link" v-model="model.website_link"
                             :rules="rules.website_link" :disabled="loading"/>
            <checkbox-input class="col-6" label="Disable" v-model="model.disabled"
                            :rules="rules.disabled" :disabled="loading"/>
        </div>

        <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
             loading-text="Saving..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name     : 'AddPartner',
    computed : { ...mapGetters(['currentUser']) },
    data () {
        return {
            addUrl : urls.admin.partner.addEdit,
            rules  : {
                name : {
                    required : true
                },
                description : {
                    max      : 240,
                    required : true
                },
                logo : {
                    required : true
                },
                website_link : {
                    required : true
                },
                disabled : {
                    required : false
                }
            }
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Partner..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        },

        formFailure (response) {
            const json = response.data;
            if (json.permission === false) {
                this.$notify('Invalid Authentication..!', 'Permission Denied',
                    {
                        type : 'warning'
                    }
                );
            }
        }
    }
};
</script>

<style scoped>

</style>
