<template>
    <div class="card bs-5 mb-5">
        <div class="fl-te-c pb-3">
            <h4 v-html="'PARTNER'"></h4>
            <div class="btn-group">
                <btn v-if="partnerBanner.length===0" color="primary" size="md"
                     @click="$refs.partnerBannerAddModal.show()" text="Add Partner Banner"/>
                <btn v-else color="primary" size="md" @click="$refs.partnerBannerEditModal.show()"
                     text="Update Partner Banner"/>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.partnerAddModal.show()" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #logo="{rowData}">
                <img :src="prefix + rowData.logo" width="150px" alt="img">
            </template>
            <template #disabled="{rowData}">
                <checkbox-input v-model="rowData.disabled" @input="disable(rowData)"></checkbox-input>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <delete-btn @click="setDelete(rowData)"/>
                    <edit-btn @click="setEdit(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add Partner Banner" ref="partnerBannerAddModal" width="30r" :no-close-on-backdrop="true"
               header-color="primary">
            <b-form :save-url="partnerBannerAddEditUrl" @success="formSuccessBanner" v-slot="{model, loading}"
                    :save-params="{api_key: currentUser.api_key, type:'Partner'}">

                <div class="row">
                    <validated-input class="col-12" label="Title" v-model="model.title"
                                     :rules="{required:true}" :disabled="loading"/>

                    <file-input class="c-file-input col-12" label="Image" v-model="model.image" :disabled="loading"/>
                </div>

                <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
                     loading-text="Saving..." :disabled="loading"/>
            </b-form>
        </modal>

        <modal title="Update Partner Banner" ref="partnerBannerEditModal" width="30r" :no-close-on-backdrop="true"
               header-color="primary">
            <b-form :save-url="partnerBannerAddEditUrl" @success="formSuccessBanner" v-slot="{model, loading}"
                    :save-params="{api_key: currentUser.api_key}" :initial-data="partnerBanner[0]">

                <div class="row">
                    <validated-input class="col-12" label="Title" v-model="model.title"
                                     :rules="{required:true}" :disabled="loading"/>
                    <div class="col">
                        Current Image
                    </div>
                    <div class="col">
                        <img :src="partnerBanner[0].image" height="200" width="420px" alt="img"/>
                    </div>
                    <div class="col">
                        <image-info :width="640" :height="500"/>
                    </div>
                    <file-input class="c-file-input col-12" label="Update Image" name="Image" v-model="model.image" :disabled="loading"/>
                </div>

                <btn class="trn" size="sm" :loading="loading" color="primary" text="Update"
                     loading-text="Updating..." :disabled="loading"/>
            </b-form>
        </modal>

        <modal title="Add Partner" ref="partnerAddModal" width="60r" :no-close-on-backdrop="true"
               header-color="primary">
            <AddPartner @success="formSuccess"></AddPartner>
        </modal>

        <modal title="Edit Partner" ref="partnerEditModal" :no-close-on-backdrop="true" width="60r"
               header-color="primary">
            <EditPartner :initial-data="editingItem" @success="formSuccess"></EditPartner>
        </modal>

        <delete-modal ref="partnerDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Partner <b v-html="deletingItem && deletingItem.partner_name"></b>. Are you
                sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../data/urls';
import AddPartner from './AddPartner';
import EditPartner from './EditPartner';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';

export default {
    name       : 'Partner',
    computed   : { ...mapGetters(['currentUser']) },
    components : { AddPartner, EditPartner },
    data () {
        return {
            prefix                  : '',
            partnerBanner           : [],
            partnerBannerAddEditUrl : urls.admin.event.banner.addPartnerBannerEdit,
            listUrl                 : urls.admin.partner.list,
            deleteUrl               : urls.admin.partner.delete,
            editingItem             : null,
            deletingItem            : null,
            fields                  : [
                {
                    name       : 'name',
                    sortField  : 'name',
                    title      : 'Partner Name',
                    titleClass : ''
                },
                {
                    name       : 'description',
                    sortField  : 'description',
                    title      : 'Description',
                    titleClass : ''
                },
                {
                    name       : 'website_link',
                    sortField  : 'website_link',
                    title      : 'Website Link',
                    titleClass : ''
                },
                {
                    name       : '__slot:logo',
                    sortField  : 'logo',
                    title      : 'Logo',
                    titleClass : ''
                },
                {
                    name       : '__slot:disabled',
                    sortField  : 'disabled',
                    title      : 'Disabled',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    mounted () {
        this.loadPartnerBanner();
    },
    methods : {
        async loadPartnerBanner () {
            const response = await axios.get(urls.admin.partner.bannerList);
            if (response.data) {
                this.partnerBanner = response.data.data;
            }
        },
        formSuccessBanner () {
            this.$notify('Successfully Added Partner Banner..!', 'Success',
                {
                    type : 'success'
                }
            );
            const refs = this.$refs;
            refs.partnerBannerAddModal.close();
            refs.partnerBannerEditModal.close();
            this.loadPartnerBanner();
        },
        formSuccess () {
            const refs = this.$refs;
            refs.partnerAddModal.close();
            refs.partnerEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.partnerEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.partnerDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Partner..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.partnerDeleteModal.close();
            this.$refs.table.refreshTable();
        },
        disable (item) {
            const that = this;
            const key = {
                api_key : that.currentUser.api_key
            };
            const data = {
                ...key,
                ...item
            };
            axios.form(urls.admin.partner.addEdit, data).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify('Successfully Updated Partner..!', 'Success',
                        {
                            type : 'success'
                        });
                } else {
                    that.$notify(json.errors, 'Error',
                        {
                            type : 'warning'
                        });
                    item.disabled = !item.disabled;
                }
            }).catch(function (err) {
                item.disabled = !item.disabled;
                console.log('error : ', err);
                that.$notify('Something went wrong..!', 'Success',
                    {
                        type : 'warning'
                    });
            });
        }
    }
};
</script>

<style scoped>

</style>
