<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" @failure="formFailure"
            :initial-data="initialData" :save-params="{api_key: currentUser.api_key}">

        <div class="row">
            <validated-input class="col-6" label="Partner Name" v-model="model.name"
                             :rules="rules.name" :disabled="loading"/>

            <file-input class="c-file-input col-6" label="Logo" v-model="model.logo" :disabled="loading">
                <template #append v-if="model.logo">
                    <btn class="ml-auto" type="button" size="xs"
                         title="View Image" @click="viewFile(model.logo)"/>
                </template>
            </file-input>
        </div>
        <div class="row">
            <div class="col">
                <image-info :width="270" :height="160"/>
            </div>

            <div class="col-12">
                <validated-textarea label="Description" v-model="model.description"
                                    :rules="rules.description" :disabled="loading"/>
            </div>
        </div>

        <div class="row">
            <validated-input class="col-6" label="Website link" v-model="model.website_link"
                             :rules="rules.website_link" :disabled="loading"/>
            <checkbox-input label="Disable" v-model="model.disabled"
                            :rules="rules.disabled" :disabled="loading"/>
        </div>
        <btn class="trn" size="sm" :loading="loading" color="primary" text="Update"
             loading-text="Updating..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name     : 'EditPartner',
    props    : { initialData : { type : Object } },
    computed : { ...mapGetters(['currentUser']) },
    data () {
        return {
            addUrl : urls.admin.partner.addEdit,
            rules  : {
                name : {
                    required : true
                },
                description : {
                    max      : 240,
                    required : true
                },
                logo : {
                    required : true
                },
                website_link : {
                    required : true
                },
                disabled : {
                    required : false
                }
            }
        };
    },

    methods : {
        viewFile (url) {
            window.open(url);
        },
        formSuccess () {
            this.$notify('Successfully Edited Partner..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        },

        formFailure (response) {
            const json = response.data;
            if (json.permission === false) {
                this.$notify('Invalid Authentication..!', 'Permission Denied',
                    {
                        type : 'warning'
                    }
                );
            }
        }
    }
};
</script>

<style scoped>

</style>
